<template>
  <div class="header-container__content px-9 py-5 d-flex flex-wrap flex-lg-nowrap align-center">
    <TopHeaderSignUp />
    <AppTabs />
    <slot />
    <div
      v-if="userInfo"
      class="d-flex align-center mb-0 ml-auto gap-3">
      <UpgradeBtn :user-id="userId" />
      <FeedbackAndTraining />
      <HeaderAccountMenu />
    </div>
  </div>
</template>
<script>
export default {
  name: 'AppHeaderLayoutDesktop',
  components: {
    AppTabs: () => import('@/components/App/AppTabs'),
    FeedbackAndTraining: () => import('@/components/App/AppHeaderFeedbackAndTraining'),
    HeaderAccountMenu: () => import('@/components/App/AppHeaderAccountMenu'),
    TopHeaderSignUp: () => import('@/components/App/AppTopHeaderSignUp'),
    UpgradeBtn: () => import('@/components/App/AppHeader/AppHeaderLayout/AppHeaderLayoutUpgradeBtn'),
  },
  props: {
    userInfo: {
      type: Object,
      default: null,
    },
    userId: {
      type: String,
      default: null,
    },
  },
};
</script>
<style scoped lang="scss">
.header-container__content {
  flex-grow: 1;
  height: 90px;
}
</style>
